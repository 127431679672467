import { render, staticRenderFns } from "./ProductProcessIndex.vue?vue&type=template&id=e460cfee&scoped=true&"
import script from "./ProductProcessIndex.vue?vue&type=script&lang=js&"
export * from "./ProductProcessIndex.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e460cfee",
  null
  
)

export default component.exports